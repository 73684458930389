import React, { useState, useEffect } from 'react';
import PinInput from 'react-pin-input';
import db from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import pic from './  (10).png';

function App() {
  const [pin, setPin] = useState('');
  console.log(pin);

  const [cloudPin, setCloudPin] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'family_docs'));
      const data = querySnapshot.docs.map(doc => doc.data());
      setCloudPin(data[0].pin);
    }
    fetchData();
    console.log('Data fetched', cloudPin);
  }, [cloudPin]);

  const handleInput = (value, index) => {
    setPin(value);
    if (value.length === 4) {
      if (value === cloudPin) {

        window.location.href = 'https://drive.google.com/drive/folders/1M-bgkK3aX6Gf1Wl6G9sgA5bS0R643zd7?usp=sharing';
        
      } else {
        alert('Password Not Matched');
        window.location.reload();
      }
    }
  }

  return (
    <div style={styles.container}>
      <img src={pic} alt="Family Image" style={styles.image}/>
      <h1 style={styles.h1}>Welcome to this Family App</h1>
      <h2 style={styles.h2}>To enter this, We are waiting for your Family Code.</h2>
      <h3 style={styles.h3}>Enter the 4 digit Family Code</h3>
      <PinInput 
        length={4} 
        initialValue=""
        secret
        secretDelay={100} 
        onChange={(value, index) => {
          handleInput(value, index);
        }} 
        type="numeric" 
        inputMode="number"
        style={{padding: '10px'}}  
        inputStyle={{borderColor: 'blue'}}
        inputFocusStyle={{borderColor: 'green'}}
      />
    </div>
  );
}

export default App;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)',
  },
  h1: {
    color: 'blue',
    fontSize: '2rem',
    fontWeight: 'bold',
    fontFamily: 'Tisa',
    textAlign: 'left', 
  },
  h2: {
    color: 'green',
    fontSize: '1.5rem',
    padding: '10px',
    marginLeft: '10px'
  },
  h3: {
    color: 'red',
    fontSize: '1.2rem'
  },
  image: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginBottom: '10px'
  }
}